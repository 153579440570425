import { Button, Checkbox, Container, Image, InputNumber, Label, Modal, Radio } from 'app/components';
import { SectionEntities } from 'app/components/Entity';
import { useConfig, useModal } from 'app/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { MainSection, SelectionHeadingSelection, SelectionItemSelection, SelectionSelection, SubSection, SubSection1, SubSection2, ButtonSection, SelectionMobileBoxMenu } from './styles';
import { RowButton } from '../styles';
import CrossIcon from '../../../assets/images/close.png';
import { MainSectionMobileView } from './styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const BoxMenu = ({ fetchComboItem, comboId, setConcessionData,
  notify, remove, languageContentsForPopup, languageContents, isModalOpen, windowSizes, setIsModalOpen, setConcessionComboItem }) => {
  const {
    api: { endpoint },
    currencyForCountry
  } = useConfig();
  const modal = useSelector(state => state.layout.modals['BoxMenu']);
  const [t, i18n] = useTranslation();

  const { language } = i18n;

  const [BoxMenu, showBoxMenu, hideBoxMenu] = useModal('BoxMenu');
  const [windowSize, setWindowSize] = useState();
  const [summeDetails, setSummeDetails] = useState(false);
  const [isExpanded, toggleExpand] = useState(false);
  const comboItemData = useSelector(state => state?.concessions?.comboItemData);
  const [selectedItems, setSelectedItems] = useState({});
  const selectedItemsData = useSelector(state => state?.concessions?.concessionData);
  const [selectedArrayItems, setSelectedArrayItems] = useState([]);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const toastersId = useSelector(state => state.layout.toasters);

  const handleSummeDetail = (item, id) => {
    const session = 'comboSession';
    const updatedItems = {
      normalSession: { ...selectedItemsData.normalSession },
      comboSession: { ...selectedItemsData.comboSession }
    };

    const childItems = selectedItemsData?.comboSession?.[comboId?.itemId]?.packageChildItems || [];

    const maxQuantity = childItems.reduce((max, currentItem) => {
      return Math.max(max, currentItem.quantity || 1);
    }, 1);

    if (selectedItemsData && comboId && selectedItemsData['comboSession'][comboId?.itemId]?.packageChildItems?.length === 0) {
      delete updatedItems['comboSession'][comboId?.itemId];
    } else {
      updatedItems[session][id] = {
        ...item.comboSession[id],
        quantity: maxQuantity,
      };
    }
    setConcessionData(updatedItems);
    toggleExpand(!isExpanded);
    setSummeDetails(!summeDetails);
    remove(toastersId[0]?.id);
    setIsModalOpen(!isModalOpen);
  };

  const handleCheckboxChange = (itemIdx, description, altItem, quantity) => {
    setSelectedItems(prevSelectedItems => {
      const updatedSelectedItems = { ...prevSelectedItems };

      if (!updatedSelectedItems[description]) {
        updatedSelectedItems[description] = [];
      }

      const isSelected = updatedSelectedItems[description].some(item => item.ItemId === altItem.Id);
      const selectedCount = updatedSelectedItems[description].length;

      if (isSelected) {
        updatedSelectedItems[description] = updatedSelectedItems[description].filter(item => item.ItemId !== altItem.Id);
      } else if (selectedCount < quantity) {
        updatedSelectedItems[description] = [
          ...updatedSelectedItems[description],
          {
            ItemId: altItem.Id,
            // Description: altItem.Description,
            quantity: 1,
            ParentSaleItem: {
              ItemId: itemIdx
            }
          }
        ];
      } else {
        updatedSelectedItems[description] = [
          ...updatedSelectedItems[description].slice(1),
          {
            ItemId: altItem.Id,
            // Description: altItem.Description,
            quantity: 1,
            ParentSaleItem: {
              ItemId: itemIdx
            }
          }
        ];
      }
      return updatedSelectedItems;
    });
  };

  const handleQuantityChange = (itemIdx, description, altItem, newQuantity, maxQuantity) => {
    const totalSelectedQuantity = selectedItems[description]?.reduce((sum, item) => sum + item.quantity, 0) || 0;

    const existingItemQuantity = selectedItems[description]?.find(item => item.ItemId === altItem.Id)?.quantity || 0;
    if (totalSelectedQuantity - existingItemQuantity + newQuantity > maxQuantity) {
      onMaxNotify();
      return;
    }

    setSelectedItems(prevSelectedItems => {
      const updatedSelectedItems = { ...prevSelectedItems };

      if (!updatedSelectedItems[description]) {
        updatedSelectedItems[description] = [];
      }

      const existingItemIndex = updatedSelectedItems[description].findIndex(item => item.ItemId === altItem.Id);

      if (newQuantity === 0) {
        updatedSelectedItems[description] = updatedSelectedItems[description].filter(item => item.ItemId !== altItem.Id);
      } else if (existingItemIndex !== -1) {
        updatedSelectedItems[description][existingItemIndex].quantity = newQuantity;
      } else {
        updatedSelectedItems[description].push({
          ItemId: altItem.Id,
          quantity: newQuantity,
          ParentSaleItem: {
            ItemId: itemIdx
          }
        });
      }

      return updatedSelectedItems;
    });
  };

  const handleDirectSelection = (item) => {
    const itemData = selectedItemsData.comboSession[comboId?.itemId];
    if (!item || item.length === 0 || !itemData) return;
    const { id, description } = item;
    if (comboId?.itemId === itemData?.id) {
      setSelectedItems(() => {
        const updatedSelectedItems = {};

        if (!updatedSelectedItems[description]) {
          updatedSelectedItems[description] = [];
        }

        updatedSelectedItems[description] = [{
          ItemId: id,
          quantity: 1
        }];

        return updatedSelectedItems;
      });
    }
  };

  useEffect(() => {
    const concessionData = Object.values(selectedItems).flat();
    if (concessionData.length > 0) {
      setSelectedArrayItems(concessionData);
    } else {
      setSelectedArrayItems([]);
    }

    const totalQuantityComboItems = comboItemData?.reduce((sum, item) => sum + item?.quantity, 0);

    const totalQuantitySelectedItems = Object.keys(selectedItems).reduce((total, key) => {
      return total + selectedItems[key]?.reduce((sum, item) => sum + item?.quantity, 0);
    }, 0);

    if (totalQuantityComboItems === totalQuantitySelectedItems) {
      setDisableAddBtn(false);
    }
    else {
      setDisableAddBtn(true);
    }
  }, [selectedItems]);

  const updateItemQuantity = (item, description) => (newQuantity) => {
    const session = 'comboSession';
    const updatedItems = {
      normalSession: { ...item.normalSession },
      comboSession: { ...item.comboSession }
    };
    if (newQuantity === 0) {
      delete updatedItems[session][description];
    } else {
      updatedItems[session][description] = {
        ...item.comboSession[description],
        quantity: newQuantity,
      };
    }
    setConcessionData(updatedItems);
  };

  const handleClearComboData = (item, id) => {
    const session = 'comboSession';
    const updatedItems = {
      normalSession: { ...selectedItemsData.normalSession },
      comboSession: { ...selectedItemsData.comboSession }
    };

    const childItems = selectedItemsData?.comboSession?.[comboId?.itemId]?.packageChildItems || [];


    const maxQuantity = childItems.reduce((max, currentItem) => {
      return Math.max(max, currentItem.quantity || 1);
    }, 1);


    if (selectedItemsData && comboId && selectedItemsData['comboSession'][comboId?.itemId]?.packageChildItems?.length === 0) {
      delete updatedItems['comboSession'][comboId?.itemId];
    } else {
      updatedItems[session][id] = {
        ...item.comboSession[id],
        quantity: maxQuantity,
      };
    }
    setConcessionData(updatedItems);
    setIsModalOpen(!isModalOpen);
    remove(toastersId[0]?.id);
  }

  const handleAddComboData = () => {
    if (!selectedItemsData.comboSession) {
      selectedItemsData.comboSession = {};
    }

    const finalPayloadItems = [];
    Object.values(selectedItemsData.comboSession).forEach(item => {
      if (item.id === comboId?.itemId) {
        const newItem = {
          itemId: item.id,
          cinemaId: item.cinemaId,
          quantity: 1,
          packageChildItems: selectedArrayItems
        };
        finalPayloadItems.push(newItem);
      }
    });

    selectedItemsData.comboSession[comboId?.itemId]["packageChildItems"].push(...finalPayloadItems);

    setConcessionData(selectedItemsData);
    setIsModalOpen(!isModalOpen);
  }

  useEffect(() => {
    setConcessionComboItem(null);
  }, []);

  useEffect(() => {
    fetchComboItem(comboId)
  }, [comboId]);

  useEffect(() => {
    setWindowSize(window.innerWidth);
  }, [window]);

  window.addEventListener('resize', function () {
    setWindowSize(window.innerWidth);
  }, true);

  const onMaxNotify = useCallback(() => {
    const id = notify('warning', [
      'purchase.toasters.order_restriction_box_menu',
      { amount: 2 }
    ]);
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      if (windowSizes >= 992) {
        showBoxMenu();
      }
      else {
        setSummeDetails(!summeDetails);
        toggleExpand(!isExpanded)
      }
    }
  }, [isModalOpen, modal]);

  useEffect(() => {
    if (modal === false) {
      setIsModalOpen(!isModalOpen);
    }
  }, [modal]);

  useEffect(() => {
    if (isModalOpen && comboItemData?.length) {
      const item = comboItemData?.find((item) => {
        return item?.alternateItems?.length === 0;
      });
      if (item) {
        handleDirectSelection(item);
      }
    }
  }, [isModalOpen, comboItemData]);

  const quantity = selectedItemsData['comboSession'][comboId?.itemId]?.quantity;
  const priceInCents = (selectedItemsData['comboSession'][comboId?.itemId]?.priceInCents / 100).toFixed(2);
  const description = selectedItemsData['comboSession'][comboId?.itemId]?.description;
  const img = selectedItemsData['comboSession'][comboId?.itemId]?.imagePath;
  return (
    <SectionEntities>
      <Container>
        {
          windowSize >= 992 ?
            (
              <div className='boxMenu_title'>
                {
                  comboItemData?.length &&
                  <Modal
                    isOpen={BoxMenu}
                    close={hideBoxMenu}
                    title={t('common.box_menu_title')}
                    isBoxMenu={true}
                  // style={{ pointerEvents: 'none' }}
                  // shouldCloseOnOverlayClick={false}
                  >
                    <MainSection>
                      <SubSection>
                        <SubSection1>
                          <div className='image-section'>
                            <Image className="rewardImage" src={`${endpoint}${img}`} />
                          </div>
                          <div className='content-section'>
                            <div className='content-heading'>{description}</div>
                            {comboItemData?.length && comboItemData?.map((item, index) => (
                              <div className='content-subHeading'>
                                {/* <div>{quantity * item?.quantity} X {item?.[languageContents?.description]}</div> */}
                                <div>{quantity * item.quantity} X {item?.DescriptionTranslations
                                  ? item?.DescriptionTranslations.find(translation => translation.LanguageTag.split("-")[0] === language)?.Text || item.description
                                  : item?.description
                                }</div>
                              </div>
                            ))}
                          </div>
                        </SubSection1>
                        <SubSection2>
                          <div>
                            <div className='total-section'>
                              {/* <div className='boxMenu_inputField'>
                                <InputNumber
                                  min={1}
                                  value={quantity || 0}
                                  onChange={updateItemQuantity(selectedItemsData, comboId?.itemId)}
                                />
                              </div> */}
                              <div className='total-sub-section'>
                                <span className='sub-section1'>{quantity} X {currencyForCountry} {priceInCents}</span>
                                <span className='sub-section2'> {currencyForCountry} {(quantity * priceInCents).toFixed(2)}</span>
                              </div>
                            </div>
                          </div>
                        </SubSection2>
                      </SubSection>
                    </MainSection>
                    <SelectionSelection>
                      {comboItemData?.length && comboItemData?.filter(item => item.alternateItems?.length > 0)?.map((item, index) => {
                        const totalSelectedQuantity = selectedItems[item.description]?.reduce((sum, selectedItem) => sum + selectedItem.quantity, 0) || 0;
                        return (
                          <>
                            <SelectionHeadingSelection key={item?.idx} style={{ marginTop: index === 0 ? "0px" : "24px" }}>
                              <div className='header-section'>
                                <div className='number-section'><span className='number-sub-section'>{index + 1}</span></div>
                                <span className='heading-content-section'>
                                  {/* <p className='heading-content-subSection1'>{item?.[languageContents?.description]}</p> */}
                                  <p className='heading-content-subSection1'>{item?.DescriptionTranslations
                                    ? item?.DescriptionTranslations.find(translation => translation.LanguageTag.split("-")[0] === language)?.Text || item.description
                                    : item?.description
                                  }</p>
                                  <p className='heading-content-subSection2'>max. {item.quantity}</p>
                                </span>
                              </div>
                            </SelectionHeadingSelection>
                            <SelectionItemSelection>
                              {item?.alternateItems?.length > 0 &&
                                item?.alternateItems.map((altItem, ind) => {
                                  const selectedQuantity = selectedItems[item.description]?.find(selectedItem => selectedItem.ItemId === altItem.Id)?.quantity || 0;
                                  return (
                                    <Label className="box-menu-radio" style={{ borderTop: ind === 0 && ('0px'), cursor: 'pointer' }} key={altItem.Id}>
                                      {/* {altItem?.[languageContentsForPopup?.Description]} */}
                                      {altItem?.DescriptionTranslations
                                        ? altItem?.DescriptionTranslations.find(translation => translation.LanguageTag.split("-")[0] === language)?.Text || altItem.Description
                                        : altItem?.Description
                                      }
                                      {item?.quantity === 1 ? <Checkbox
                                        id={altItem.Id}
                                        checked={selectedItems[item.description]?.some(selectedItem => selectedItem.ItemId === altItem.Id) || false}
                                        onChange={() => handleCheckboxChange(item.id, item.description, altItem, item.quantity)}
                                      />
                                        :
                                        <>
                                          <InputNumber
                                            value={selectedQuantity}
                                            max={item?.quantity}
                                            onChange={(value) => handleQuantityChange(item.id, item.description, altItem, value, item.quantity)}
                                            onMax={onMaxNotify}
                                            disabled={totalSelectedQuantity >= item.quantity && selectedQuantity === 0}
                                          />
                                        </>
                                      }
                                    </Label>
                                  )
                                })
                              }
                            </SelectionItemSelection >
                          </>
                        )
                      })
                      }
                    </SelectionSelection>
                    <ButtonSection isDisable={disableAddBtn}>
                      <div className='main-div'>
                        <div className='clear-btn' onClick={() => handleClearComboData(selectedItemsData, comboId?.itemId)}>{t('common.box_menu_clear')}</div>
                        <div className='add-btn'
                          onClick={() => handleAddComboData()}
                        >{t('common.box_menu_add')}</div>
                      </div>
                    </ButtonSection>
                  </Modal>
                }
              </div>
            )
            :
            (
              <>
                {
                  summeDetails &&
                  <SelectionMobileBoxMenu className={isExpanded ? '_expanded' : ''}>
                    <div
                      className="b-selection-total-mob__mask"
                      aria-hidden
                    />
                    <div
                      className="b-selection-total-mob__info-wrapper"
                      aria-hidden
                      style={{
                        flexDirection: 'column'
                      }}
                    >
                      <MainSectionMobileView>
                        <div className='sub-menu'>
                          <p className='title'>{t('common.box_menu_title')}</p>
                          <span className='cross-img'><img src={CrossIcon} alt="crossIcon" onClick={() => handleSummeDetail(selectedItemsData, comboId?.itemId)} /></span>
                        </div>
                      </MainSectionMobileView>
                      <MainSection>
                        <SubSection>
                          <SubSection1>
                            <div className='image-section'>
                              <Image className="rewardImage" src={`${endpoint}${img}`} />
                            </div>
                            <div className='content-section'>
                              <div className='content-heading'>{description}</div>
                              {comboItemData?.length && comboItemData?.map((item, index) => (
                                <div className='content-subHeading'>
                                  {/* <div>{quantity * item.quantity} X {item?.[languageContents?.description]}</div> */}
                                  <div>{quantity * item.quantity} X {item?.DescriptionTranslations
                                    ? item?.DescriptionTranslations.find(translation => translation.LanguageTag.split("-")[0] === language)?.Text || item.description
                                    : item?.description
                                  }</div>
                                </div>
                              ))}
                            </div>
                          </SubSection1>
                        </SubSection>
                      </MainSection>
                      <SelectionSelection>
                        {comboItemData?.length && comboItemData?.filter(item => item.alternateItems?.length > 0)?.map((item, index) => {
                          const totalSelectedQuantity = selectedItems[item.description]?.reduce((sum, selectedItem) => sum + selectedItem.quantity, 0) || 0; return (
                            <>
                              <SelectionHeadingSelection key={item?.idx} style={{ marginTop: index === 0 ? "0px" : "24px" }}>
                                <div className='header-section'>
                                  <div className='number-section'><span className='number-sub-section'>{index + 1}</span></div>
                                  <span className='heading-content-section'>
                                    <p className='heading-content-subSection1'>{item?.[languageContents?.description]}</p>
                                    <p className='heading-content-subSection2'>max. {item.quantity}</p>
                                  </span>
                                </div>
                              </SelectionHeadingSelection>
                              <SelectionItemSelection>
                                {item?.alternateItems?.length > 0 &&
                                  item?.alternateItems.map((altItem, ind) => {
                                    const selectedQuantity = selectedItems[item.description]?.find(selectedItem => selectedItem.ItemId === altItem.Id)?.quantity || 0;
                                    return (
                                      <Label className="box-menu-radio" style={{ borderTop: ind === 0 && ('0px'), cursor: 'pointer' }}>
                                        {/* {altItem?.[languageContentsForPopup?.Description]} */}
                                        {altItem?.DescriptionTranslations
                                          ? altItem?.DescriptionTranslations.find(translation => translation.LanguageTag.split("-")[0] === language)?.Text || altItem.Description
                                          : altItem?.Description
                                        }
                                        {item?.quantity === 1 ? <Checkbox
                                          id={altItem.Id}
                                          checked={selectedItems[item.description]?.some(selectedItem => selectedItem.ItemId === altItem.Id) || false}
                                          onChange={() => handleCheckboxChange(item.id, item.description, altItem, item.quantity)}
                                        />
                                          :
                                          <>
                                            <InputNumber
                                              value={selectedQuantity}
                                              max={item?.quantity}
                                              onChange={(value) => handleQuantityChange(item.id, item.description, altItem, value, item.quantity)}
                                              onMax={onMaxNotify}
                                              disabled={totalSelectedQuantity >= item.quantity && selectedQuantity === 0}
                                            />
                                          </>
                                        }
                                      </Label>
                                    )
                                  })
                                }
                              </SelectionItemSelection >
                            </>
                          )
                        })
                        }
                      </SelectionSelection>
                      <div className='total-section-box-menu'>
                        <SubSection2>
                          <div>
                            {/* <div className='total-section-mobile-view'> */}
                            {/* <div className='boxMenu_inputField'>
                                <InputNumber
                                  min={1}
                                  value={quantity || 0}
                                  onChange={updateItemQuantity(selectedItemsData, comboId?.itemId)}
                                />
                              </div> */}
                            <div className='total-sub-section'>
                              <span className='sub-section1'>{quantity} X {currencyForCountry} {priceInCents}</span>
                              <span className='sub-section2'>{currencyForCountry} {quantity * priceInCents}</span>
                            </div>
                            {/* </div> */}
                          </div>
                        </SubSection2>
                        <SelectionSelection>
                          {/* <div className="total-sub-section">{renderSelectedItems()}</div>
                          {Object.keys(selectedItems)?.length > 0 && <div className="price-in-mobile-view"><strong>£ {calculateTotalPrice()}</strong></div>} */}
                        </SelectionSelection>
                        <RowButton style={{
                          padding: '16px 20px'
                        }}>
                          <Button isPrimary
                            onClick={() => handleAddComboData()}
                            disabled={disableAddBtn}
                          >
                            Add
                          </Button>
                        </RowButton>
                      </div>
                    </div>
                  </SelectionMobileBoxMenu>
                }
              </>
            )
        }
      </Container>
    </SectionEntities >
  )
}

export default BoxMenu;
